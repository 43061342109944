import { createStore, Store } from 'vuex'
import { InjectionKey } from 'vue'
import router from '@/router'
import createPersistedState from "vuex-persistedstate";
import axios from 'axios';
import Cookies from 'js-cookie';
import {AxiosError} from 'axios';

// define typings for the store state
export interface State {
  email: string,  
  password: string, 
  loggedin: boolean
}

export interface appConfig {
  root: string,
  firebaseKey: string,
  vAPIkey: string
}

// define injection key
export const key: InjectionKey<Store<State>> = Symbol();

const _TimeOut = 10000;

export const store = createStore({
  
  state: {
    appConfig: {} as appConfig,
    email: null,
    password: null,
    loggedin: false,
    homepage: null,
    configuration: null,
    tokenKey: null,
    email_code: null,
    user_profile: null,
    social_links: null,
    path_after_login: ''
  },

  plugins: [createPersistedState(
    {
      reducer: state => ({
        appConfig: state.appConfig,
        email: state.email,
        password: state.password,
        loggedin: state.loggedin,
        homepage: state.homepage,
        configuration: state.configuration,
        tokenKey: state.tokenKey,
        email_opt: state.email_code,
        user_profile: state.user_profile,
        social_links: state.social_links,
        path_after_login: state.path_after_login
      }),
   
      storage: {
        getItem: key => Cookies.get(key),
        setItem: (key, value) => Cookies.set(key, value, { 
          expires: 365, secure: true }
        ),
        removeItem: key => Cookies.remove(key)
      }    
    }
  )],

  mutations: {
    appConfig(state, appConfig) {
      state.appConfig = appConfig;
    },
   
    setUser(state, loginDetails) {
      if(loginDetails) {
        state.email = loginDetails.username;
        state.password = loginDetails.password;
      }
    },

    Logout(state) {
      state.email = null;
      state.password = null;
      state.loggedin = false;
      state.tokenKey = null;
      state.email_code = null;
      state.user_profile = null;
      state.path_after_login = '';
    },

    saveToken(state, key) {
      state.tokenKey = key;
      state.loggedin = true;
      sessionStorage.setItem("token", key); // save token in session
    },
    saveLoginPath(state, key) {
      state.path_after_login = key;
      sessionStorage.setItem("path_after_login", key); // save token in session
    },

    setEmailAndOpt(state, signUpDetails) {
      state.email = signUpDetails.email;
      state.email_code = signUpDetails.code;
      localStorage.setItem('email', signUpDetails.email);
      localStorage.setItem('email_verification', signUpDetails.code);
    }
  },

  actions: {

    /** Sign Up with Email */
    async verifyEmail({state}, requestBody){
        const config = {
          timeout: _TimeOut
        };
        const URL = `${process.env.VUE_APP_ROOT_API}/api/users/accounts/verify_email/`;
        return new Promise((resolve, reject) => { 
          axios.post( URL, requestBody, config)
          .then(res => 
            {
                if(res.data) {
                  const results = res.data;
                  resolve(results);
                }    
            }
          )
          .catch(error => {
            console.log(error);
            if(error == 'Error: timeout of ' + _TimeOut + 'ms exceeded')
              alert('Something went wrong!'); // backend error
            reject(error); 
          });
        });     
    },

    async preValidateCode({state}, requestBody) {
      console.log('OTP HERE', requestBody);
      
      // TODO: Move this section inside the axios post when the response is success.
      store.commit('setEmailAndOpt', requestBody); 

      const config = {
        timeout: _TimeOut
      };

      const URL = `${process.env.VUE_APP_ROOT_API}/api/users/accounts/pre_validate_code/`;
      return new Promise((resolve, reject) => { axios.post( URL, requestBody, config )
        .then(res => 
          {
            const results = res.data;
            if(results) {
              resolve(results);
            } 
            else {
              reject(results);
            }
          }
        )
        .catch(error => reject(error));
      });
    },

    async Register({state}, requestBody){

      const config = {
        timeout: _TimeOut
      };

      const URL = `${process.env.VUE_APP_ROOT_API}/api/users/accounts/register/`;
      return new Promise((resolve, reject) => { axios.post( URL, requestBody, config )
        .then(res => 
          {
            const results = res.data;
            if(results) {
              resolve(results);

              console.log('Register Res', results)

              if(results.key)
                store.commit('saveToken', results.key);

              const loginDetails = {
                username: requestBody.email,
                password: requestBody.password1
              }

              store.commit('setUser', loginDetails);

              if(results.onboarding_completed == false) 
                router.push({ path: '/personal-information', name: 'PersonalInformation'}); 
              else
                if (this.state.path_after_login !== '')
                  router.push({ path: this.state.path_after_login});
                else
                  router.push({ path: 'restaurants', name: 'Restaurants'});


            } 
            else {
              reject(results);
            }
          }
        )
        .catch((reason : AxiosError) => 
          {
            if(reason.response?.data && reason.response?.data) {
              if( reason.response.data.non_field_errors &&  reason.response.data.non_field_errors.length > 0 ) {
               alert(reason.response.data.non_field_errors);
              }
             }
          }
        );
      });

    },

    async verifyPhone({state}, phone){
      
        const config = {
          headers: {'Authorization': 'Token ' + state.tokenKey},
          timeout: _TimeOut
        };
        const requestBody = {
          phone_number: phone
        }
        const URL = `${process.env.VUE_APP_ROOT_API}/api/users/accounts/verify_phone_number/`;

        return new Promise((resolve, reject) => { 
          axios.post( URL, requestBody, config)
          .then(res => 
            {
                if(res.data) {
                  const results = res.data;
                  resolve(results);
                }    
            }
          )
          .catch((reason: AxiosError) => {
            if(reason && reason.response) {
              if(reason.response.data && reason.response.data.phone_number && reason.response.data.phone_number.length > 0) 
                alert(reason.response.data.phone_number[0]);
              else if (reason.response.data && reason.response.data.detail){
                alert(reason.response.data.detail);
              }
            }
            else alert('Your phone number may already be linked to an existing account!');

            reject(reason); 
          });
        });     
        
    },

    async updatePhoneNo({state}, requestBody) {
      console.log('OTP HERE', requestBody);
      
      const config = {
        headers: {'Authorization': 'Token ' + state.tokenKey},
        timeout: _TimeOut
      };

      const URL = `${process.env.VUE_APP_ROOT_API}/api/users/profiles/my/update_phone_number/`;
      return new Promise((resolve, reject) => { axios.post( URL, requestBody, config )
        .then(res => 
          {
            const results = res.data;
            console.log('back ', results);
            if(results) {
              resolve(results);
            } 
            else {
              reject(results);
            }
          }
        )
        .catch(error => { 
          reject(error);
          console.log('back ', error);
        }
        );
      });

    },
    /** End Sign Up with Email */

    // Login with Email API
    async Login({ state }, loginDetails){

      const config = {
        timeout: _TimeOut
      };
      const URL = `${process.env.VUE_APP_ROOT_API}/api/users/accounts/login/`;

      return new Promise((resolve, reject) => { 
        axios.post( URL, loginDetails, config)
        .then(res => 
          {
            if(res.data) {
              const results = res.data;

              console.log(results);
              resolve(results);
              
              if(results.key)
                store.commit('saveToken', results.key);

              store.commit('setUser', loginDetails);

              // TODO: redirect to last visited Restaurants or Home
              if(results.onboarding_completed == false) 
                router.push({ path: '/personal-information', name: 'PersonalInformation'}); 
              else
                if (this.state.path_after_login !== '')
                  router.push({ path: this.state.path_after_login});
                else
                  router.push({ path: 'restaurants', name: 'Restaurants'});
            }    
          }
        )
        .catch(error => {
          console.log(error) 
          if(error == 'Error: timeout of ' + _TimeOut + 'ms exceeded')
            alert('Something went wrong!'); // backend error
          reject(error); 
        });
      });     
    },

    // Facebook Login API
    async FacebookLogin({ state }, loginDetails){
      const config = {
        timeout: _TimeOut
      };
      const URL = `${process.env.VUE_APP_ROOT_API}/api/users/accounts/facebook_login/`;

      return new Promise((resolve, reject) => { 
        axios.post( URL, loginDetails, config)
        .then(res => 
          {
            if(res.data) {
              const results = res.data;

              console.log(results);
              resolve(results);
              
              if(results.key)
                store.commit('saveToken', results.key);

              if(results.onboarding_completed == false) 
                router.push({ path: '/personal-information', name: 'PersonalInformation'}); 
              else
                if (this.state.path_after_login !== '')
                  router.push({ path: this.state.path_after_login});
                else
                  router.push({ path: 'restaurants', name: 'Restaurants'});
            }    
          }
        )
        .catch(error => {
          console.log(error) 
          if(error == 'Error: timeout of ' + _TimeOut + 'ms exceeded')
            alert('Something went wrong!'); // backend error
          reject(error); 
        });
      });     
    },

    // Google Login API
    async GoogleLogin({ state }, loginDetails){
      const config = {
        timeout: _TimeOut
      };
      const URL = `${process.env.VUE_APP_ROOT_API}/api/users/accounts/google_login/`;

      return new Promise((resolve, reject) => { 
        axios.post( URL, loginDetails, config)
        .then(res => 
          {
            if(res.data) {
              const results = res.data;

              console.log(results);
              resolve(results);
              
              if(results.key)
                store.commit('saveToken', results.key);

              if(results.onboarding_completed == false) 
                router.push({ path: '/personal-information', name: 'PersonalInformation'}); 
              else
                if (this.state.path_after_login !== '')
                  router.push({ path: this.state.path_after_login});
                else
                  router.push({ path: 'restaurants', name: 'Restaurants'});
            }    
          }
        )
        .catch(error => {
          console.log(error) 
          if(error == 'Error: timeout of ' + _TimeOut + 'ms exceeded')
            alert('Something went wrong!'); // backend error
          reject(error); 
        });
      });     
    },


    async LogOut({commit}){
      commit('Logout')
    },

    async userProfile ({state}) {
      if(!state.tokenKey)
        return;
      const config = {
        headers: {'Authorization': 'Token ' + state.tokenKey},
        timeout: _TimeOut,
      };
      const URL = `${process.env.VUE_APP_ROOT_API}/api/users/profiles/my/`;
      return new Promise((resolve, reject) => { axios.get( URL, config )
        .then(res => 
          {
            const results = res.data;
            if(results) {
              state.user_profile = results;
              resolve(results);
            } 
            else {
              reject(results);
            }
          }
        )
        .catch(error => reject(error));
      });
    },

    async updatePartialProfile ({state}, requestBody){
      const config = {
        headers: {'Authorization': 'Token ' + state.tokenKey},
        timeout: _TimeOut
      };

      const URL = `${process.env.VUE_APP_ROOT_API}/api/users/profiles/my/`;
      return new Promise((resolve, reject) => { axios.patch( URL, requestBody, config )
        .then(res => 
          {
            const results = res.data;
            if(results) {
              resolve(results);
            } 
            else {
              reject(results);
            }
          }
        )
        .catch(error => reject(error));
      });

    },

    async homePageContents () {
      const config = {
        timeout: _TimeOut
      };
      const URL = `${process.env.VUE_APP_ROOT_API}/api/users/website/homepage/`;
      return new Promise((resolve, reject) => { axios.get( URL, config )
        .then(res => 
          {
            const results = res.data;
            if(results) {
              resolve(results);
            } 
            else {
              reject(results);
            }
          }
        )
        .catch(error => reject(error));
      });


    },

    async webConfiguration ({ state }) {
      const config = {
        timeout: _TimeOut
      };
      const URL = `${process.env.VUE_APP_ROOT_API}/api/users/website/configuration/`;
      return new Promise((resolve, reject) => { axios.get( URL, config )
        .then(res => 
          {
            const results = res.data;
            if(results) {
              state.configuration = results;
              resolve(results);
            } 
            else {
              reject(results);
            }
          }
        )
        .catch(error => reject(error));
      });


    },

    async aboutUsContents () {
      const config = {
        timeout: _TimeOut
      };

      const URL = `${process.env.VUE_APP_ROOT_API}/api/users/website/about_us/`;
      return new Promise((resolve, reject) => { axios.get( URL, config )
        .then(res => 
          {
            const results = res.data;
            if(results) {
              resolve(results);
            } 
            else {
              reject(results);
            }
          }
        )
        .catch(error => reject(error));
      });
    },

    async TermsAndConditions () {
      const config = {
        timeout: _TimeOut
      };

      const URL = `${process.env.VUE_APP_ROOT_API}/api/common/static/legal_documents/`;
      return new Promise((resolve, reject) => { axios.get( URL, config )
        .then(res => 
          {
            const results = res.data;
            if(results) {
              resolve(results);
            } 
            else {
              reject(results);
            }
          }
        )
        .catch(error => reject(error));
      });
    },

    async teamContents () {
      const config = {
        timeout: _TimeOut
      };
     
      const URL = `${process.env.VUE_APP_ROOT_API}/api/users/website/team/`;
      return new Promise((resolve, reject) => { axios.get( URL, config )
        .then(res => 
          {
            const results = res.data;
            if(results) {
              resolve(results);
            } 
            else {
              reject(results);
            }
          }
        )
        .catch(error => reject(error));
      });
    },

    async contactUs ({state}, requestBody) {
      const config = {
        timeout: _TimeOut
      };
      const URL = `${process.env.VUE_APP_ROOT_API}/api/users/website/contact_us/`;

      return new Promise((resolve, reject) => { axios.post( URL, requestBody, config )
        .then(res => 
          {
            const results = res.data;
            if(results) {
              resolve(results);
            } 
            else {
              reject(results);
            }
          }
        )
        .catch(error => reject(error));
      });
    },

    /** Restaurants */
    async allRestaurants ({state}, requestBody) {
      const config = {
        timeout: _TimeOut
      } as any;

      if(state.tokenKey) {
        config.headers = {'Authorization': 'Token ' + state.tokenKey}
      }
      
      const URL = `${process.env.VUE_APP_ROOT_API}/api/users/restaurants/all/`;
      return new Promise((resolve, reject) => { axios.post( URL, requestBody, config )
        .then(res => 
          {
            const results = res.data;
            if(results) {
              resolve(results);
              
            } 
            else {
              reject(results);
            }
          }
        )
        .catch((reason: AxiosError) => {
          console.log(reason.response)
        });
      });
    },

    async restaurantByID ({ state }, ID) {
      const config = {
        timeout: _TimeOut
      };
      const URL = `${process.env.VUE_APP_ROOT_API}/api/users/restaurants/${ID}/`;
      return new Promise((resolve, reject) => { axios.get( URL, config )
        .then(res => 
          {
            const results = res.data;
            if(results) {
              resolve(results);
            } 
            else {
              reject(results);
            }
          }
        )
        .catch(error => reject(error));
      });
    },

    async allCities () {
      const config = {
        timeout: _TimeOut
      };
      
      const URL = `${process.env.VUE_APP_ROOT_API}/api/common/locations/cities/`;
      return new Promise((resolve, reject) => { axios.get( URL, config )
        .then(res => 
          {
            const results = res.data;
            if(results) {
              resolve(results);
            } 
            else {
              reject(results);
            }
          }
        )
        .catch(error => reject(error));
      });
    },

    async cuisineTypes () {
      const config = {
        timeout: _TimeOut
      };
      
      const URL = `${process.env.VUE_APP_ROOT_API}/api/common/configuration/cuisine_types/`;
      return new Promise((resolve, reject) => { axios.get( URL, config )
        .then(res => 
          {
            const results = res.data;
            if(results) {
              resolve(results);
            } 
            else {
              reject(results);
            }
          }
        )
        .catch(error => reject(error));
      });
    },

    async menuByRestaurantID ({ state }, ID) {
      const config = {
        timeout: _TimeOut
      };
      const URL = `${process.env.VUE_APP_ROOT_API}/api/users/restaurants/${ID}/menu/`;
      return new Promise((resolve, reject) => { axios.get( URL, config )
        .then(res => 
          {
            const results = res.data;
            if(results) {
              resolve(results);
            } 
            else {
              reject(results);
            }
          }
        )
        .catch(error => reject(error));
      });
    },

    async specialMenuByRestaurantID ({ state }, ID) {
      const config = {
        timeout: _TimeOut
      };
      const URL = `${process.env.VUE_APP_ROOT_API}/api/users/restaurants/special_menus/${ID}/menus/`;
      return new Promise((resolve, reject) => { axios.get( URL, config )
        .then(res => 
          {
            const results = res.data;
            if(results) {
              resolve(results);
            } 
            else {
              reject(results);
            }
          }
        )
        .catch(error => reject(error));
      });
    },

    async specialMenuItems ({ state }, [restaurant_id, menu_id]) {
      const config = {
        timeout: _TimeOut
      };
      
      const requestBody = { special_menu: menu_id}
      const URL = `${process.env.VUE_APP_ROOT_API}/api/users/restaurants/special_menus/${restaurant_id}/menu_categories/`;
      return new Promise((resolve, reject) => { axios.post( URL, requestBody, config )
        .then(res => 
          {
            const results = res.data;
            if(results) {
              resolve(results);
            } 
            else {
              reject(results);
            }
          }
        )
        .catch(error => reject(error));
      });
    },

    async availabilitySlots ({ state }, requestBody) {
      const config = {
        timeout: _TimeOut
      };
      const URL = `${process.env.VUE_APP_ROOT_API}/api/users/restaurants/dine_in/${requestBody.ID}/availability_slots/`;
      const postBody = {
        date: requestBody.date
      } as any

      if(requestBody.area != 0) postBody.area = requestBody.area;

      return new Promise((resolve, reject) => { axios.post( URL, postBody, config )
        .then(res => 
          {
            const results = res.data;
            if(results) {
              resolve(results);
            } 
            else {
              reject(results);
            }
          }
        )
        .catch(error => reject(error));
      });
    },

    async sendTableRequest ({state}, [requestBody, ID]) {

      const config = {
        headers: {'Authorization': 'Token ' + state.tokenKey},
        timeout: _TimeOut
      };

      const URL = `${process.env.VUE_APP_ROOT_API}/api/users/restaurants/dine_in/${ID}/send_table_request/`;
      return new Promise((resolve, reject) => { axios.post( URL, requestBody, config )
        .then(res => 
          {
            const results = res.data;
            if(results) {
              resolve(results);
            } 
            else {
              reject(results);
            }
          }
        )
        .catch((reason: AxiosError) => { 
          // validate errors
          if(reason && reason.response && reason.response.data) {
            if(reason.response.data.detail)
              alert(reason.response.data.detail);
            else if(reason.response.data.smoking_preference && reason.response.data.smoking_preference.length > 0)
                  alert(reason.response.data.smoking_preference[0]);
            else if (reason.response.data.datetime && reason.response.data.datetime.length > 0)
                  alert(reason.response.data.datetime[0]);
            else alert('Something went wrong, please try again!');
          } 
          else alert('Something went wrong, please try again!');
           
          console.log(reason.response);
          reject(reason);
          
        });
      });

    },

    async dineinAvailabilityStatuses () {
      const config = {
        timeout: _TimeOut
      };

      const URL = `${process.env.VUE_APP_ROOT_API}/api/common/attributes/dine_in_avail_statuses/`;
      return new Promise((resolve, reject) => { axios.get( URL, config )
        .then(res => 
          {
            const results = res.data;
            if(results) {
              resolve(results);
            } 
            else {
              reject(results);
            }
          }
        )
        .catch(error => reject(error));
      });
    },

    /* Payment */
    async makeTableOnly ({state}, [requestBody, ID]) {

      const config = {
        headers: {'Authorization': 'Token ' + state.tokenKey},
        timeout: _TimeOut
      };

      console.log('makeTableOnly body ', requestBody);

      const URL = `${process.env.VUE_APP_ROOT_API}/api/users/orders/dine_in/${ID}/make_table_only/`;
      return new Promise((resolve, reject) => { axios.post( URL, requestBody, config )
        .then(res => 
          {
            const results = res.data;
            if(results) {
              resolve(results);
            } 
            else {
              reject(results);
            }
          }
        )
        .catch(error => reject(error));
      });

    },

    async loadPayment ({state}, transactionID) {
      if(!state.tokenKey)
        return;

      const config = {
        headers: {'Authorization': 'Token ' + state.tokenKey},
        timeout: _TimeOut,
      };

      console.log(config);
      const URL = `${process.env.VUE_APP_ROOT_API}/authorize_payment/${transactionID}/`;
      return new Promise((resolve, reject) => { axios.get( URL, config )
        .then(res => 
          {
            console.log(res)
            const results = res.data;
            if(results) {

              resolve(results);
            }
          }
        )
        .catch(error => reject(error));
      });
    },


  },
  getters: {
    // user is Authenticated once the loggedin is retrieved
    isAuthenticated: (state) => !!state.loggedin,
  }
})