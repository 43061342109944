import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-column min-vh-100" }
const _hoisted_2 = { class: "container container-fluid pt-5" }
const _hoisted_3 = { class: "fw-bold pt-5 mb-3" }
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_PageLoader = _resolveComponent("PageLoader")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Header),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.page_items)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createElementVNode("h5", _hoisted_3, _toDisplayString(_ctx.page_items.document_description), 1),
            _createElementVNode("div", {
              class: "mb-5 pb-4",
              innerHTML: _ctx.page_items.content
            }, null, 8, _hoisted_4)
          ], 64))
        : (_openBlock(), _createBlock(_component_PageLoader, { key: 1 }))
    ]),
    _createVNode(_component_Footer)
  ]))
}