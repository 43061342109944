
import { defineComponent } from 'vue';
import LoginSideImage from '@/components/pages/auth/LoginSideImage.vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import countries from '@/data/CountryCode'
import { store } from '@/store';
import ValidatePhoneCode from './ValidatePhoneCode.vue';
import ThankYouModal from './ThankYouModal.vue';

export default defineComponent({
    components: { 
      Form, Field, ErrorMessage, LoginSideImage, ValidatePhoneCode,  ThankYouModal
    },
       
    name: 'PersonalInformation',

    data() {
        return {
            saving: false,
            profile: {
                first_name: '',
                last_name: '',
                onboarding_completed: false,
            },

            email: '',
            countries: null as any,
            dial_code: '+357',
            phoneChanged: false,
            showOtpPanel: false,
            phone_number: '',
            phone: ''

        }
    },

    created() {
        this.userProfile();
        this.countries = countries;
        console.log(this.profile);
    },

    methods: {
        
        userProfile(){
            store.dispatch('userProfile').then(res => {
                if(res)
                {
                    store.state.user_profile = res;
                    let profile = store.state.user_profile as any;
                    this.email = profile.email;
                    this.getCountryCode(profile.phone_number);

                    // if(res.referral_code) 
                      //  this.profile.signup_referral_code = profile.referral_code;
                    // }

                    this.profile.first_name = profile.first_name;
                    this.profile.last_name = profile.last_name;
                }
            });
        },

        updatePhone(){
            this.phoneChanged = true;
        },

        closePanel(value: boolean) {
            if(!value) {
                this.phoneChanged = true;
            } else if(value) {
              this.phoneChanged = false;
              this.updateProfile();
                // post phone number to user profile
            }
            this.showOtpPanel = false;
        },

        getCountryCode(phone: string) {
            if(!phone)
                return;

            for(let i= 0; i < this.countries.length; i++) {
                let isCode = phone.includes(this.countries[i].dial_code);
                if(isCode) {
                    this.dial_code =  this.countries[i].dial_code;
                    if(this.dial_code)
                    this.phone = phone.split(this.dial_code)[1];
                    return;
                }
            }
        },

        async updateProfile() {
            
            // Update profile
            this.saving = true;
            if(this.phoneChanged) {
                this.phone_number = this.dial_code + this.phone;
                
                if(this.phone_number) {
                    // post to phone verification API
                    store.dispatch('verifyPhone', this.phone_number).then((res) => {
                        if(res) {
                            this.showOtpPanel = true;
                            this.saving = false;
                        }

                    }).catch((error) => {
                        console.log('verify phone error', error);
                        this.saving = false;
                    });
                }
            }

            else {

                this.profile.onboarding_completed = true;
                await store.dispatch('updatePartialProfile', this.profile).then(res =>
                { 
                    console.log('profile is updated', res);

                    //show thank you popup
                    this.saving = false;
                   
                    const trigger = document.getElementById("triggerModal") as HTMLInputElement;
                    trigger.click();
                })
                .catch(error => {
                    this.saving = false;
                    console.log(error)
                }); 
            }
            
        },

        async logout() {
            await store.dispatch('LogOut');
            this.$router.push({ path: 'signin', name: 'Login'});
        },
    }
})
