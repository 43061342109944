
    import { ref, defineComponent } from 'vue';
    import router from '@/router';
    import VOtpInput from 'vue3-otp-input';
    import PreLoader from '@/components/shared/loaders/PreLoader.vue';
    import { store } from '@/store';
    

  export default defineComponent({

    name: 'ValidateEmailCode',
    props: ['email'],

    components: {
        VOtpInput, PreLoader
    },

    setup(email) {
        const otpInput = ref(null) as any;
        const onError = ref(false) as any;
        const onSuccess = ref(false) as any;
        const onLoad = ref(false) as any;
        
    
        const handleOnComplete = async (otp: string) => {
            if(otp && email) {
                const requestBody = {
                    email: email.email,
                    code: otp
                };

                onLoad.value = true;
                await store.dispatch('preValidateCode', requestBody).then(result => {
                    if(result) {
                        console.log('preValidateCode', result); //success: true
                        console.log('preValidateCode', result.success); //success: true
                         onSuccess.value = true;
                         onLoad.value = false;
                        if(store.state.email_code && store.state.email) {   
                            // if the email and code are verified, then send user to the save password page.
                            router.push({ path: '/save-password', name: 'SavePassword'});
                        }
                    }
                })
                .catch(error => {
                    if(error) {
                        onError.value = true; 
                        onSuccess.value = false;   
                        onLoad.value = false;
                    }
                });
            }
        };

        const handleOnChange = (value: string) => {
            onError.value = false;
        };

        return { handleOnComplete, handleOnChange, otpInput, onLoad, onError, onSuccess };
    },

    methods: {
        async resendOTP() {

            await store.dispatch('verifyEmail', {email: this.email}).then(res =>
                { 
                    if(res) {
                        console.log(res);
                        this.onSuccess, this.onError = false;
                        // Clear OTP
                        if(this.otpInput)
                            this.otpInput.clearInput();
                        }
                }
                )
                .catch(error => {
                if(error) {
                    console.log(error)
                }
            }); 
        }
    }
  });
