
  import { defineComponent} from 'vue';
  import LoginSideImage from '@/components/pages/auth/LoginSideImage.vue';
  import { defineRule , Form, Field, ErrorMessage } from 'vee-validate';
  import ValidateEmailCode from './signup/ValidateEmailCode.vue';
  import Facebook from '@/components/pages/auth/signup/Facebook.vue';
  import Google from '@/components/pages/auth/signup/Google.vue';
  import AllRules from '@vee-validate/rules';
  import { store } from '@/store';
  

  Object.keys(AllRules).forEach(rule => {
    defineRule(rule, AllRules[rule]);
  });

  export default defineComponent({
    components: { 
      Form, Field, ErrorMessage, LoginSideImage, ValidateEmailCode, Facebook, Google
    },
    name: 'Signup',

    data() {
      return {
        email: '',
        logging: false,
        email_available: false,
        email_exists: ''
      }
    },

    methods: {
      async SignUp() {
        this.logging = true;
        
        let emailObj = { email: this.email };

        // dispatch to userAuth action
        await store.dispatch('verifyEmail', emailObj).then(res =>
          { 
            if(res) {
              this.email_exists = '';
              this.email_available = true; // hide longin error msg if exist
            }
          }
        )
        .catch(error => {
          if(error) {
            this.logging = false;       // disable the loading during verify email  
            this.email_available = false;  
            this.email_exists = 'This email address is already being used!'
          }
        }); 
      }
    }
  });
