
    import { defineComponent } from 'vue';
    import getUTCDate from '@/data/DateMaxins'

    export default defineComponent({
        name: 'DetailsTab',
        props: ['restaurantDetails'],
        mixins: [getUTCDate],
        data() {
            return {
                dine_in_hours: [] as any
            }
        },
        created(){
            this.openingHours();
        },
        methods: {
            getAddress(address: any){
                // Format the restauarant address
                let full_address = address.address_line1 ? address.address_line1 : '';
                full_address += address.address_line1 ? ' ' + address.address_line1 : '';
                full_address += address.city && address.city.name ? ' ' + address.city.name : '';
                full_address += address.city && address.city.country && address.city.country.name ? ' ' + address.city.country.name : '';
                return full_address;
            },
            openingHours() {
                const name = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
                const now = new Date().toUTCString();
                let gg  = Date.parse(now);
                const DAY = 60 * 60 * 24 * 1000;
                for (let i = 0; i < 7; i++) {
                    const date = new Date(gg + DAY * i);
                    let day = this.getUTCDate(date);
                    
                    if(this.restaurantDetails && this.restaurantDetails.dine_in_hours && day) {
                        let Obj =  { 
                            date: name[date.getUTCDay()], 
                            hours: this.restaurantDetails.dine_in_hours[day],
                            isToday: i == 0 ? true : false
                        };
                        this.dine_in_hours.push(Obj);
                    }
                }
            }
        },

       
  });
