import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Login from '@/components/pages/auth/LoginPage.vue';
import Signup from '@/components/pages/auth/SignUpPage.vue';
import SavePassword from '@/components/pages/auth/signup/SavePassword.vue';
import PersonalInformation from '@/components/pages/auth/signup/PersonalInformation.vue';

import Featured from '@/components/pages/Home.vue';
import About from '@/components/pages/About.vue';
import Restaurants from '@/components/pages/Restaurants.vue'
import RestaurantPage from '@/components/pages/restaurants/RestaurantPage.vue';
import Team from '@/components/pages/Team.vue';
import Contact from '@/components/pages/Contact.vue';
import TermsAndPolicy from '@/components/pages/TermsAndPolicy.vue';
import Typography from '@/components/pages/Typography.vue';
import { store } from '@/store';


const routes: Array<RouteRecordRaw> = [
  {
    path: '/signin',
    name: 'Login',
    component: Login,
    meta: { nonLoggedin: true },
  },
  {
    path: '/signup',
    name: 'Signup',
    component: Signup,
    meta: { nonLoggedin: true },
  },
  {
    path: '/save-password',
    name: 'SavePassword',
    component: SavePassword
  },
  {
    path: '/personal-information',
    name: 'PersonalInformation',
    component: PersonalInformation,
    meta: { requiresAuth: true },
  },
  {
    path: '/restaurants',
    alias: '/',
    name: 'Restaurants',
    component: Restaurants
  },
  {
    path: '/featured',
    name: 'Featured',
    component: Featured
  },
  {
    path: '/about-us',
    name: 'About',
    component: About
  },
  {
    path: '/team',
    name: 'Team',
    component: Team
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/restaurants/:slug?/:id/:tab?',
    name: 'RestaurantPage',
    component: RestaurantPage
  },
  {
    path: '/legal/:category',
    name: 'TermsAndPolicy',
    component: TermsAndPolicy,
  },
  {
    path: '/typo',
    name: 'Typography',
    component: Typography,
  }
   
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from) {
    if(to.name == "RestaurantPage" && from.name == "RestaurantPage")
      return;
    // always scroll to top
    return { top: 0 };
  },
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isAuthenticated) {
      next();
      return;
    }
    next("/signin");
  } else {
    if(to.matched.some((record) => record.meta.nonLoggedin)) {
      const user_profile = store.state.user_profile as any;
      if (store.getters.isAuthenticated) {
        if(user_profile && !user_profile.onboarding_completed) {
          store.dispatch('LogOut');
        }
        next("/restaurants");
      }
    }
    next();
  }
});
export default router;
