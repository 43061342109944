
    import { defineComponent } from 'vue';
    import getCurrency from '@/data/GeneralMixins';
    import { store } from '@/store';

    export default defineComponent({
        name: 'RestaurantMenu',
        props: ['restaurantDetails', 'MenuID'],
        mixins: [getCurrency],
        emits: ['hasItems'],

        data() {
            return {
                menu_categories: []
            }
        },

        created(){
            if(this.restaurantDetails && this.restaurantDetails.id) {
                if(this.MenuID) {
                    this.specialMenuItems(this.restaurantDetails.id, this.MenuID);
                } else {
                    this.menuByRestaurantID(this.restaurantDetails.id);
                }
            }
        },
        methods: {
            menuByRestaurantID(ID: number) {
                store.dispatch('menuByRestaurantID', ID).then(res => {
                    if(res) {
                       this.menu_categories = res;
                    }
                });
            },
            specialMenuItems(restaurant_id: number, menu_id: number) {
                store.dispatch('specialMenuItems', [restaurant_id, menu_id]).then(res => {
                    if(res) {
                        // override menu_categories with the special menu items
                       this.menu_categories = res;
                       if(res && res.length > 0) {
                        this.$emit('hasItems', menu_id);
                       }
                    }
                });
            },
        }

  });
