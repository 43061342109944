
  import { defineComponent } from 'vue';
  import Header from '@/components/shared/PageHeader.vue';
  import Footer from '@/components/shared/PageFooter.vue';

  export default defineComponent({
    name: 'Typography',
    
    components: { 
      Header, Footer
    },
   
    beforeMount() {
      this.setBackground();
    },

    created(){
      this.setBackground();
    },

    methods: {
      setBackground() { 
        document.body.className = 'bg-light';
      }
    }
  });
