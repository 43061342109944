import { defineComponent } from "vue";

export default defineComponent ({
  methods: {
    formatDate(timestamp: Date) {
      // Create a date object from the timestamp
      const date = new Date(timestamp);
      return new Intl.DateTimeFormat('default', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      }).format(date);
    },
    formatFullDate(timestamp: Date) {
      // Create a date object from the timestamp
      const date = new Date(timestamp);
      return new Intl.DateTimeFormat('en-GB', {
        year: 'numeric',
        weekday: 'long',
        month: 'short',
        day: 'numeric'
      }).format(date);
    },
    getDashedDate(timestamp: Date) {
      // Create a date object from the timestamp

      let date = new Date(timestamp) as any;
      const dd = String(date.getDate()).padStart(2, '0');
      const mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
      const yyyy = date.getFullYear();

      date = yyyy  + '-' + mm + '-' + dd;

      return date;
      
    },
    getSlashedDate(timestamp: Date) {
      // Create a date object from the timestamp

      let date = new Date(timestamp) as any;
      const dd = String(date.getDate()).padStart(2, '0');
      const mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
      const yyyy = date.getFullYear();

      date = dd + '/'+ mm + '/' +  yyyy;

      return date;
      
    },

    getUTCDate(timestamp: Date) {
      // Create a date object from the timestamp

      let date = new Date(timestamp) as any;
      const dd = String(date.getUTCDate()).padStart(2, '0');
      const mm = String(date.getUTCMonth() + 1).padStart(2, '0'); //January is 0!
      const yyyy = date.getUTCFullYear();

      date = dd + '/'+ mm + '/' +  yyyy;

      return date;
      
    },
    getDaySlashMonthFormat(timestamp: Date) {
      // Create a date object from the timestamp

      let date = new Date(timestamp) as any;
      const dd = String(date.getDate());
      const mm = String(date.getMonth() + 1); 

      date =  dd + '/' + mm;

      return date;
      
    },
    getTime(timestamp: Date) {
      const date = new Date(timestamp);
      return new Intl.DateTimeFormat('en-GB', {
        hour: 'numeric', 
        minute: 'numeric',
        timeZone: 'Europe/Athens'
        
      }).format(date);
    },

  }
});