
  import { defineComponent } from 'vue';
  import Header from '@/components/shared/PageHeader.vue';
  import Footer from '@/components/shared/PageFooter.vue';
  import ListLoader from '@/components/shared/loaders/ListLoader.vue';
  import restaurantURL from '@/data/GeneralMixins';
  import { store } from '@/store';
    
  export default defineComponent({
    name: 'Restaurants',
    
    components: { 
      Header, Footer, ListLoader
    }, 

    mixins: [restaurantURL],
    
    data() {
        return {
            all_restaurants: [],
            all_cities: null as any,
            cuisine_types: null as any,
            selected_city: '',
            selected_cuisine: '',
            search_text: '',
            no_records: false,
            all_loaded: false,

        }
    },

    created() {
        this.allRestaurants();
        this.allCities();
        this.cuisineTypes();
    },

    methods: {
        async allRestaurants() {

            this.all_restaurants = [];
            this.no_records = false;

            const requestBody = {} as any;

            if(this.selected_city)
                requestBody.city = this.selected_city;

            if(this.selected_cuisine)
                requestBody.cuisines = [this.selected_cuisine];

            if(this.search_text) {
                requestBody.search_query = this.search_text;
            }

            await store.dispatch('allRestaurants', requestBody).then(res => {
                if(res && res.results) {
                    this.all_restaurants = res.results;
                    this.no_records = false;
                    this.all_loaded = false;

                    if(this.all_restaurants.length == 0) {
                        this.no_records = true;
                    }
                }
            });

            
        },

        async allCities() {
            await store.dispatch('allCities').then(res => {
                if(res && res.results) {
                    this.all_cities = res.results;
                    if(this.all_cities)
                        this.all_cities.sort((a: any,b: any) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
                }
            });
        },
        
        async cuisineTypes() {
            await store.dispatch('cuisineTypes').then(res => {
                if(res && res.results) {
                    this.cuisine_types = res.results;
                    if(this.cuisine_types)
                        this.cuisine_types.sort((a: any,b: any) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
                }
            });
        },
    }
  });
