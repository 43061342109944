
  import { defineComponent } from 'vue';
  import Header from '@/components/shared/PageHeader.vue';
  import Footer from '@/components/shared/PageFooter.vue';
  import Slider from '@/components/shared/Slider.vue';
  import restaurantURL from '@/data/GeneralMixins';
  import { store } from '@/store';

  export default defineComponent({
    name: 'Featured',

    mixins: [restaurantURL],
    
    components: { 
      Header, Footer, Slider
    },

    data() {
      return {
        homepage: null as any,
        gallery: [] as any,
        other_content: null as any,
        featured_restaurants: [] as any,
        partners: [] as any,
      }
    },
   
    created(){
      this.loadHomepage();
    },

    mounted(){
      this.loadHomepage();
    },

    methods: {

      async loadHomepage() {
        // dispatch to homepage API
        await store.dispatch('homePageContents').then(result => {
          if(result) {

            this.gallery = result.gallery;
            this.gallery.sort((a:any, b:any) => (a.order > b.order) ? 1 : -1);
            this.other_content = result.other_content;

            this.featured_restaurants = result.featured_restaurants;

            this.partners = result.partners;
            this.partners.sort((a:any, b:any) => (a.order > b.order) ? 1 : -1);

            // group every 4 partners together
            this.partners = this.groupPartners(this.partners, 4);            
          }
        });
      },
      groupPartners(arr: [], n:number){
         var group = [];
          for (var i = 0, end = arr.length / n; i < end; ++i)
            group.push(arr.slice(i * n, (i + 1) * n));
          return group;
      }

    }


  });
