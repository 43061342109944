
import { defineComponent } from '@vue/runtime-core';
import { googleTokenLogin } from "vue3-google-login"
import { store } from '@/store';

export default defineComponent({
  name: 'Google',

  setup() {
    const login = () => {
        googleTokenLogin().then((response) => {
            console.log("Handle the response", response);
            let userRequest = {} as any;
            if (response) {
                if(response && response.access_token) {
                    userRequest.access_token = response.access_token;
                    userRequest.code = response.access_token;
                    userRequest.id_token = response.access_token;
                }

                if(userRequest && userRequest.access_token) {
                    store.dispatch('GoogleLogin', userRequest).then( res =>{
                        console.log(res);
                    });
                }
                }
            })
        }
       
        return { login };
    },


  data(){
    return {
        userRequest: {} as any
    }
  },

});
