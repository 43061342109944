
import { defineComponent } from 'vue'

export default defineComponent({
        name: 'Slider',
        props: ['gallery'],
        created(){
          if(this.$route.name === 'RestaurantPage')
            window.addEventListener("resize", this.onReferreshDimentions);
        },
        methods: {
          checkImageDimentions(elementID : string) {
           if(this.$route.name !== 'RestaurantPage')
            return false;
           
            setTimeout(() => {
              var img = document.getElementById(elementID) as HTMLImageElement; 
              if(img != null) {
                let width = img.width;
                let height = img.height;
                if(width >= 1200 && height <= 600) {
                  img.setAttribute("class", "featured-img");  
                }
              }
            }, 800);
          },

          onReferreshDimentions(e:Event){
            if(e) {
              this.checkImageDimentions('slider-0');
            }
          }
          
        }
});

