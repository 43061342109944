
  import { defineComponent } from 'vue';
  import { store } from '@/store';

  export default defineComponent({
    name: 'Footer',

    data(){
      return {
        links: [
          {
            title: 'Restaurants',
            url: '/restaurants',
            name: 'Restaurants'
          },
          {
            title: 'Featured',
            url: '/featured',
            name: 'featured'
          },
          {
            title: 'About us',
            url: '/about-us',
          },
          {
            title: 'Our Team',
            url: '/team',
            name: 'Team'
          },
          {
            title: ' Contact us',
            url: '/contact',
            name: 'Contact'
          }
        ],
        nav_name: 'navbar-dark bg-transparent',
        profile: null
      }
    },

    created () {
      window.addEventListener('scroll', this.handleScroll);
      if(this.$route.name != 'Featured') {
        this.nav_name = 'navbar-light bg-light'
      }

      this.userProfile();
    },
    beforeUnmount () {
      window.removeEventListener('scroll', this.handleScroll);
    },

    methods: {
      handleScroll () {
        // when the window is scrolled
        if(window.scrollY > 100)
          this.nav_name = 'navbar-light bg-light shadow';
        else {
          if(this.$route.name != 'Featured') {
            this.nav_name = 'navbar-light bg-light';
          } else {
            this.nav_name = 'navbar-dark bg-transparent';
          }
        }
          
      },

      userProfile(){
        if(!store.state.user_profile) {
          store.dispatch('userProfile').then(res => {
              if(res)
                this.profile = res;
                if(!res.onboarding_completed) {
                  // Logout user if the registration in not completed.
                  store.dispatch('LogOut');
                }
            }
          );
        } else {
          this.profile = store.state.user_profile;
        }
       
      },

      greatingMsg() {
        var today = new Date()
        var currentHour = today.getHours()

        if (currentHour < 12) {
          return 'Good morning';
        } else if (currentHour < 18) {
           return 'Good afternoon';
        } else {
          return 'Good evening';
        }
      },

      async logout() {
        await store.dispatch('LogOut');
        this.$router.push({ path: 'signin', name: 'Login'});
      },
    }
});
