import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "nav mb-3 category-item-tabs",
  role: "tablist"
}
const _hoisted_2 = {
  key: 0,
  class: "nav-item me-2",
  role: "presentation"
}
const _hoisted_3 = ["id", "data-bs-target"]
const _hoisted_4 = {
  class: "tab-content",
  style: {"min-meight":"400px"}
}
const _hoisted_5 = ["id"]
const _hoisted_6 = { class: "row mx-minus-25" }
const _hoisted_7 = { class: "d-flex" }
const _hoisted_8 = { class: "me-3" }
const _hoisted_9 = ["src"]
const _hoisted_10 = {
  key: 1,
  src: "/images/meal-item.png",
  width: "90"
}
const _hoisted_11 = { class: "d-flex w-100 justify-content-between align-items-center" }
const _hoisted_12 = { class: "text-muted" }
const _hoisted_13 = { class: "mb-0 fw-bold text-primary" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("ul", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menu_categories, (category, index) => {
        return (_openBlock(), _createElementBlock(_Fragment, {
          key: category.id
        }, [
          (category.menu_items && category.menu_items.length > 0)
            ? (_openBlock(), _createElementBlock("li", _hoisted_2, [
                _createElementVNode("a", {
                  href: "#",
                  class: _normalizeClass(["btn btn-outline-primary mb-2", index == 0 ? 'show active' : '']),
                  id: `#${category.slug}-tab`,
                  "data-bs-toggle": "tab",
                  "data-bs-target": `#${category.slug}`,
                  role: "tab"
                }, _toDisplayString(category.name), 11, _hoisted_3)
              ]))
            : _createCommentVNode("", true)
        ], 64))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menu_categories, (category, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: category.id,
          class: _normalizeClass(["tab-pane fade", index == 0 ? 'show active' : '']),
          id: category.slug,
          role: "tabpanel"
        }, [
          _createElementVNode("div", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(category.menu_items, (meal) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "col-md-6 p-2 border",
                key: meal.id
              }, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    (meal.photo_thumbnail.square_thumbnail)
                      ? (_openBlock(), _createElementBlock("img", {
                          key: 0,
                          src: meal.photo_thumbnail.square_thumbnail,
                          width: "90"
                        }, null, 8, _hoisted_9))
                      : (_openBlock(), _createElementBlock("img", _hoisted_10))
                  ]),
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("span", null, [
                      _createElementVNode("h5", null, _toDisplayString(meal.name), 1),
                      _createElementVNode("p", _hoisted_12, _toDisplayString(meal.description), 1)
                    ]),
                    _createElementVNode("p", _hoisted_13, _toDisplayString(_ctx.getCurrency(meal.price_currency)) + _toDisplayString(meal.price), 1)
                  ])
                ])
              ]))
            }), 128))
          ])
        ], 10, _hoisted_5))
      }), 128))
    ])
  ]))
}