import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-column min-vh-100" }
const _hoisted_2 = { class: "banner-container bg-dark text-center my-5" }
const _hoisted_3 = {
  key: 0,
  class: "featured-slider"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "container container-fluid" }
const _hoisted_7 = { class: "card contents shadow-sm mb-5 border-0" }
const _hoisted_8 = {
  class: "nav nav-tabs",
  id: "myTab",
  role: "tablist"
}
const _hoisted_9 = {
  key: 0,
  class: "nav-item",
  role: "presentation"
}
const _hoisted_10 = {
  class: "nav-item",
  role: "presentation"
}
const _hoisted_11 = {
  key: 0,
  class: "nav-item",
  role: "presentation"
}
const _hoisted_12 = {
  key: 0,
  class: "nav-item",
  role: "presentation"
}
const _hoisted_13 = ["data-bs-target", "aria-controls", "onClick"]
const _hoisted_14 = { class: "tab-content" }
const _hoisted_15 = ["id"]
const _hoisted_16 = {
  id: "success-modal",
  "data-bs-toggle": "modal",
  "data-bs-target": "#SuccessModal",
  class: "d-none",
  ref: "triggerSuccess"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_slider = _resolveComponent("slider")!
  const _component_BookTableTab = _resolveComponent("BookTableTab")!
  const _component_DetailsTab = _resolveComponent("DetailsTab")!
  const _component_RestaurantMenu = _resolveComponent("RestaurantMenu")!
  const _component_page_loader = _resolveComponent("page-loader")!
  const _component_payment_success = _resolveComponent("payment-success")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Header),
    (_ctx.restaurant_details)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("section", _hoisted_2, [
            (_ctx.restaurant_details.images && _ctx.restaurant_details.images.length)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createVNode(_component_slider, {
                    gallery: _ctx.restaurant_details.images
                  }, null, 8, ["gallery"])
                ]))
              : (_ctx.restaurant_details.featured_photo_web)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 1,
                    src: _ctx.restaurant_details.featured_photo_web,
                    class: "w-100 featured-img"
                  }, null, 8, _hoisted_4))
                : (_openBlock(), _createElementBlock("img", {
                    key: 2,
                    src: _ctx.restaurant_details.featured_photo_thumbnails.full,
                    class: "featured-img"
                  }, null, 8, _hoisted_5))
          ]),
          _createElementVNode("section", null, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("ul", _hoisted_8, [
                  (_ctx.restaurant_details.has_booking_only && _ctx.restaurant_details.has_dine_in)
                    ? (_openBlock(), _createElementBlock("li", _hoisted_9, [
                        _createElementVNode("button", {
                          class: _normalizeClass(["nav-link", _ctx.activeClass('book-table')]),
                          id: "book-tab",
                          "data-bs-toggle": "tab",
                          "data-bs-target": "#book",
                          type: "button",
                          role: "tab",
                          "aria-controls": "book",
                          "aria-selected": "true",
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updateURL('book-table')))
                        }, "Book a Table", 2)
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("li", _hoisted_10, [
                    _createElementVNode("button", {
                      class: _normalizeClass(["nav-link", _ctx.activeClass('details')]),
                      id: "details-tab",
                      "data-bs-toggle": "tab",
                      "data-bs-target": "#details",
                      type: "button",
                      role: "tab",
                      "aria-controls": "details",
                      "aria-selected": "false",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.updateURL('details')))
                    }, "Details", 2)
                  ]),
                  (_ctx.restaurant_details.show_menu)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        (_ctx.restaurant_details.has_standard_menu)
                          ? (_openBlock(), _createElementBlock("li", _hoisted_11, [
                              _createElementVNode("button", {
                                class: _normalizeClass(["nav-link", _ctx.activeClass('menu')]),
                                id: "menu-tab",
                                "data-bs-toggle": "tab",
                                "data-bs-target": "#menu-items",
                                type: "button",
                                role: "tab",
                                "aria-controls": "menu-items",
                                "aria-selected": "false",
                                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.updateURL('menu')))
                              }, "Menu", 2)
                            ]))
                          : _createCommentVNode("", true),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.special_menus, (menu) => {
                          return (_openBlock(), _createElementBlock(_Fragment, {
                            key: menu.id
                          }, [
                            (menu.enabled && menu.hasItems)
                              ? (_openBlock(), _createElementBlock("li", _hoisted_12, [
                                  _createElementVNode("button", {
                                    class: _normalizeClass(["nav-link", _ctx.activeClass(this.slugify(menu.name))]),
                                    "data-bs-toggle": "tab",
                                    "data-bs-target": `#menu-items-${menu.id}`,
                                    type: "button",
                                    role: "tab",
                                    "aria-controls": `menu-items-${menu.id}`,
                                    "aria-selected": "false",
                                    onClick: ($event: any) => (_ctx.updateURL(menu.name))
                                  }, _toDisplayString(menu.name), 11, _hoisted_13)
                                ]))
                              : _createCommentVNode("", true)
                          ], 64))
                        }), 128))
                      ], 64))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_14, [
                  _createElementVNode("div", {
                    class: _normalizeClass(["tab-pane fade", _ctx.activeClass('book-table')]),
                    id: "book",
                    role: "tabpanel",
                    "aria-labelledby": "book-tab"
                  }, [
                    _createVNode(_component_BookTableTab, { restaurantDetails: _ctx.restaurant_details }, null, 8, ["restaurantDetails"])
                  ], 2),
                  _createElementVNode("div", {
                    class: _normalizeClass(["tab-pane p-4 fade", _ctx.activeClass('details')]),
                    id: "details",
                    role: "tabpanel",
                    "aria-labelledby": "details-tab"
                  }, [
                    _createVNode(_component_DetailsTab, { restaurantDetails: _ctx.restaurant_details }, null, 8, ["restaurantDetails"])
                  ], 2),
                  _createElementVNode("div", {
                    class: _normalizeClass(["tab-pane pt-4 px-4 pb-0 fade", _ctx.activeClass('menu')]),
                    id: "menu-items",
                    role: "tabpanel",
                    "aria-labelledby": "menu-tab"
                  }, [
                    _createVNode(_component_RestaurantMenu, { restaurantDetails: _ctx.restaurant_details }, null, 8, ["restaurantDetails"])
                  ], 2),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.special_menus, (menu) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: menu.id,
                      class: _normalizeClass(["tab-pane pt-4 px-4 pb-0 fade", _ctx.activeClass(this.slugify(menu.name))]),
                      id: `menu-items-${menu.id}`,
                      role: "tabpanel",
                      "aria-labelledby": "menu-tab"
                    }, [
                      _createVNode(_component_RestaurantMenu, {
                        restaurantDetails: _ctx.restaurant_details,
                        MenuID: menu.id,
                        onHasItems: ($event: any) => (_ctx.hasItems(menu.id))
                      }, null, 8, ["restaurantDetails", "MenuID", "onHasItems"])
                    ], 10, _hoisted_15))
                  }), 128))
                ])
              ])
            ])
          ])
        ], 64))
      : (_openBlock(), _createBlock(_component_page_loader, { key: 1 })),
    _createElementVNode("button", _hoisted_16, "Success Modal", 512),
    _createVNode(_component_payment_success, {
      social_links: _ctx.$store.state.social_links
    }, null, 8, ["social_links"]),
    _createVNode(_component_Footer)
  ]))
}