
  import { defineComponent } from 'vue';
  import Header from '@/components/shared/PageHeader.vue';
  import Footer from '@/components/shared/PageFooter.vue';
  import { defineRule , Form, Field, ErrorMessage } from 'vee-validate';
  import AllRules from '@vee-validate/rules';
  import { store } from '@/store';

  Object.keys(AllRules).forEach(rule => {
    defineRule(rule, AllRules[rule]);
  });

  export default defineComponent({
    name: 'Contact',
    
    components: { 
      Header, Footer, Form, Field, ErrorMessage
    },

    data() {
      return {
        contact_details: {
          full_name: '',
          email: '',
          message: ''
        },
        submited: false,
        sending: false
      }
    },

    methods: {
      onSubmit () {
        this.submited = false;
        this.sending = true;
        store.dispatch('contactUs', this.contact_details).then(res =>
          {  
            if(res)
              alert("Thank you! We have received your message and will get back to you soon.")
              this.resetFields();
          }
        );
      },

      resetFields() {
        this.contact_details = {
          full_name: '',
          email: '',
          message: ''
        };
        this.submited = true;
        this.sending = false;
      }
    }
  });
