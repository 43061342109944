
  import { defineComponent, inject } from 'vue';
  import {store} from '@/store';

  export default defineComponent({
    
    created(){
      store.commit('appConfig', inject('appConfig')); // commit appConfig to mutations.
    },

    data() {
      return {
        preferences: [
          {
            title: 'Necessary',
            description: 'Necessary cookies help make a website usable by enabling basic functions like page navigation and access to secure areas of the website. The website cannot function properly without these cookies.',
            items: [{ label: 'Active', value: 'necessary', isRequired: true }],
          },
          {
            title: 'Preference',
            description: 'Preference cookies enable a website to remember information that changes the way the website behaves or looks, like your preferred language or the region that you are in.',
            items: [{ label: 'Active', value: 'preference' }],
          },
          {
            title: 'Statistic',
            description: 'Statistic cookies help website owners to understand how visitors interact with websites by collecting and reporting information anonymously.',
            items: [
              { label: 'Google', value: 'google', isEnable: true },
              { label: 'Faebook', value: 'facebook', isEnable: true }
            ],
          },
        ]
      }
    }
  });
