
  import { defineComponent } from 'vue';
  import Header from '@/components/shared/PageHeader.vue';
  import Footer from '@/components/shared/PageFooter.vue';
  import PageLoader from '@/components/shared/loaders/PageLoader.vue';

  import { store } from '@/store';

  export default defineComponent({
    name: 'TermsAndPolicy',
    
    components: { 
      Header, Footer, PageLoader
    },

    data() {
      return {
        page_items: null,
        page_type: 'terms-and-conditions' as any
      }
    },
   
    created(){
      this.loadContents();
      this.page_type = this.$route.params.category ? this.$route.params.category : this.page_type;
    },

    methods: {
      async loadContents() {
        // dispatch to API
        await store.dispatch('TermsAndConditions').then(result => {
          if(result) {
       
            if(this.page_type == 'terms-and-conditions') {
              this.page_items = result.filter((x:any) => x.type == 'TC')[0];
            } 
            else if(this.page_type == 'privacy-policy') {
              this.page_items = result.filter((x:any) => x.type == 'PP')[0];
            }
          }
        });
      }
    }
  });
