
    import { ref, defineComponent } from 'vue';
    import router from '@/router';
    import VOtpInput from 'vue3-otp-input';
    import { store } from '@/store';
    

  export default defineComponent({

    name: 'ValidatePhoneCode',
    props: ['phone'],
    emits: ['closePanel'],

    components: {
        VOtpInput
    },
    watch:{
      onSuccess(newValue, oldValue){
        if (newValue.value){
          this.closePanel();
        }
      }
    },
    setup(phone, { emit }) {
        const otpInput = ref(null) as any;
        const onError = ref(false) as any;
        const onSuccess = ref(false) as any;

        // const handleSuccess = () => {
        //   this.closePanel();
        // }.bind(this);
    
        const handleOnComplete = async (otp: string) => {
            if(otp && phone) {
                const requestBody = {
                    phone_number: phone.phone,
                    verification_code: otp
                };

                await store.dispatch('updatePhoneNo', requestBody).then(result => {
                    if(result) {
                        console.log('updatePhoneNo', result); //success: true
                        // if success then show success message and close the phone opt page
                        onSuccess.value = true;
                        emit('closePanel', onSuccess.value)
                        // TODO: Check if the OTP expired.
                     // this.closePanel();
                    }
                })
                .catch(error => {
                    if(error) {
                        onError.value = true; 
                        onSuccess.value = false;   
                    }
                });
            }
        };

        const handleOnChange = () => {
            onError.value = false;
        };

        return { handleOnComplete, handleOnChange, otpInput, onError, onSuccess };
    },
    data() {
        return {
            isValid: false
        }
    },

    methods: {
        resendOTP(){

            store.dispatch('verifyPhone', this.phone).then((res) => {
                if(res) {
                    console.log(res);
                    this.onSuccess, this.onError = false;
                    // Clear OTP
                    if(this.otpInput)
                        this.otpInput.clearInput();
                    
                }
            }).catch((error) => {
                console.log('verify phone error', error);
            });
        },

        closePanel(){
            this.handleOnComplete(''); // reset OTP fields
            this.$emit('closePanel', this.onSuccess.value)
        }
    }
  });
