import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import VueGtag from "vue-gtag";
import { store,  key } from './store';
import axios from 'axios';
import VueSocialSharing from 'vue-social-sharing';
import vue3GoogleLogin from 'vue3-google-login';
import VueCookieComply from 'vue-cookie-comply';
import 'vue-cookie-comply/dist/style.css';


/* Bootstrap */
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js"

/* Fontawesome */
import '@fortawesome/fontawesome-free/css/all.css';


/* Custom CSS */
import './assets/css/style.css';

axios.defaults.withCredentials = true

const appConfig = {
    // preset app configurations
    root: window.location.protocol + '//' + window.location.host,
    // firebaseKey: 'KEY HERE IF ANY',
    // vAPIkey: ' YOUR vAPIkey '
}

async function createApplication() {
    const app = createApp(App);
    app.use(vue3GoogleLogin, {
        clientId: '193796401986-5gptqkms0q2m88q8bs7jrjunumdvuver.apps.googleusercontent.com'
    });

    app.provide('appConfig', appConfig);
    app.use(VueCookieComply);
    app.use(VueSocialSharing);
    app.use(store, key);
    app.use(VueGtag, {
        config: { id: "G-0X1J1X7E0J" }
        }
    );
    app.use(router).mount('#app');
}
createApplication();