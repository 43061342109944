
    import { defineComponent } from 'vue';
    import { store } from '@/store';
    import getDashedDate from '@/data/DateMaxins';
    import getDaySlashMonthFormat from '@/data/DateMaxins';
    import getTime from '@/data/DateMaxins';
    import PaymentModal from '@/components/pages/restaurants/modals/PaymentModal.vue';
    import RequestSentModal from './modals/RequestSentModal.vue';

    export default defineComponent({
        components: { PaymentModal, RequestSentModal },
        name: 'BookTableTab',
        props: ['restaurantDetails'],
        mixins: [getDashedDate, getTime, getDaySlashMonthFormat],

        data() {
            return {
                loading_panel: false,
                
                availability_slots: [],
                availability_statuses: [],
                dinein_available: false,
                selected_day: 'today',
                requested_date: null as any,
                other_day_slection: '',
                other_days_list: [] as any,
                is_otherday: false,
                selected_time: null as any,
                selected_availability_slot: null as any,
                slot_index: -1,
                display_date: '', // formated date to be displayed in the REQUEST button ex. 29/8

                slot_days: [
                    { label: 'Today', value: 'today' },
                    { label: 'Tomorrow', value: 'tomorrow' },
                    { label: 'Other Day', value: 'other_date'}
                ],
                smoking_area: [
                    { title: 'Smoking', preferences: 'smoking'},
                    { title: 'Non Smoking', preferences: 'no-smoking'},
                    { title: 'No Preference', preferences: 'no-preference'},
                ],

                smoking_preference: 'no-preference',
                max_people: this.restaurantDetails.max_guests_per_order, //max_guests_per_order
                number_of_guests: null as any,
                areas: this.restaurantDetails.areas,
                preferred_area: 0,

                send_table_response: null,
                orderID: null,
            }
        },
        
        created() {
            this.max_people = this.restaurantDetails.max_guests_per_order ? 
                this.restaurantDetails.max_guests_per_order : this.max_people;

            let today = this.getDashedDate(new Date());
            this.requested_date = today;
            this.display_date = this.getDaySlashMonthFormat(new Date());
            this.availabilitySlots(today, 0);
            this.otherDaysList();
            this.dineinAvailabilityStatuses();
        },

        methods: {
            otherDaysList() {
                const now = Date.now();
                const DAY = 60 * 60 * 24 * 1000;
                
                for (let i =2; i < 60; i++) {
                    let date = new Date(now + DAY * i);
                    this.other_days_list.push(this.getDashedDate((date)));
                }
            },

            selectTimeSlot(item: { timeslot: Date; }, index: number) {
                this.slot_index = index;
                this.selected_availability_slot = null;
                this.selected_availability_slot = item;
                this.selected_time =  this.getTime(item.timeslot);
                if (this.selected_availability_slot.max_sitting != null)
                  this.max_people = this.selected_availability_slot.max_sitting;
            },
            
            updateDate(value: string, firstLoad: boolean){
                this.resetFields();

                if(this.loading_panel)
                    return;
                
                
                this.display_date = '';
                const today = new Date();
                let formatedDate = '';
                this.selected_day = value;
                if(value == 'today') {
                    formatedDate = this.getDashedDate(today);
                    this.display_date = this.getDaySlashMonthFormat(today);
                    this.requested_date = formatedDate;
                    this.is_otherday = false;
                    
                } else if (value == 'tomorrow') {
                    let date = new Date(today.setDate(today.getDate() + 1));
                    this.display_date = this.getDaySlashMonthFormat(date);
                    formatedDate = this.getDashedDate(date);
                    this.requested_date = formatedDate;
                    this.is_otherday = false;
                } else {
                    this.is_otherday = true;
                    if(firstLoad) {
                        this.requested_date = null;
                        this.dinein_available = false;
                    }

                    if(this.requested_date) {
                        this.display_date = this.getDaySlashMonthFormat(this.requested_date);
                        formatedDate = this.getDashedDate(this.requested_date)
                    }
                }

                if(formatedDate)
                    this.availabilitySlots(formatedDate, this.preferred_area);
            },

            onChangeOtherDay() {
                this.requested_date = null;
                
                if(this.other_day_slection) {
                    this.requested_date = this.other_day_slection;
                }

                if(this.requested_date)
                    this.updateDate('other_date', false);
            },

            setNumberOfGuests(num: number) {
                if(num)
                    this.number_of_guests = num;
            },

            onPreferredAreaUpdate(){
                // This function was called when update prefered area
                this.updateDate(this.selected_day, false);
            },

            areaCategories(area: any) {
                if(!area) {
                    this.areas = this.restaurantDetails.areas;
                    return;
                }
                this.preferred_area = 0;
                this.smoking_preference = area.preferences;
                let areas = this.restaurantDetails.areas;
           
                if(area.preferences != null && area.preferences  != 'no-preference') {
                    let isSmoking = area.preferences == 'smoking' ? true : false;
                    this.areas = areas.filter((x : any) => x.smoking_area == isSmoking);
                } 
                else 
                    this.areas = areas;

                // Pre-select preference if area list has only one option
                if(this.areas.length == 1) {
                    this.preferred_area = this.areas[0].id;
                }
                
                //this.updateDate(this.selected_day, false);
            },

            resetFields() {
                this.availability_slots = [];
            },

            async availabilitySlots(date: string, area = 0) {
                this.loading_panel = true;
                const requestBody = {
                    ID: this.restaurantDetails.id,
                    date: date,
                    area: area
                } 

                await store.dispatch('availabilitySlots', requestBody).then(res => {
                    if(res) {
                        this.availability_slots = res;
                        this.evaluateSlot();

                        if(res.length > 0)
                            this.dinein_available = true;
                        else
                            this.dinein_available = false;
                        
                        this.loading_panel = false;
                        
                    } 
                    else {
                        this.dinein_available = false;
                        this.loading_panel = false;
                    }
                });

            },

            evaluateSlot(){
                let availability_slots = this.availability_slots as any;
                 if(this.slot_index && availability_slots[this.slot_index]) {
                    this.selected_availability_slot = availability_slots[this.slot_index]; 
                }

                for(let i = 0; i < availability_slots.length; i++) {
                    if(this.selected_time == this.getTime(availability_slots[i].timeslot)){
                        return;
                    }
                }
              
                this.selected_time = null;
            },

            isValidRequest() {
                return this.display_date && 
                this.number_of_guests && 
                this.selected_time && 
                this.smoking_preference &&
                this.selected_availability_slot.availability != 'NA' &&
                    (!this.restaurantDetails.has_table_reservation_management || (this.areas.length > 0 ? this.preferred_area != 0 : true) ? true : false);
            },

            async dineinAvailabilityStatuses() {
                await store.dispatch('dineinAvailabilityStatuses').then(res => {
                    if(res) {
                        this.availability_statuses = res;
                    }
                });
            },

            async submitRequest(){
                // send_table_request API if (no_show_fee || deposit) => go to payment 
                // Else request make table only

                if(!store.getters.isAuthenticated) {
                    // alert('Please login before proceed to send your request!');
                    // return;
                    this.$store.commit('saveLoginPath', this.$route.path);
                    await this.$router.push({path: '/signin'});
                    return;
                }
                
                if(!this.isValidRequest())
                    return false;

                let vm = this;

                // Date to UTC
                // let date = new Date(this.requested_date + ' ' + this.selected_time);
                // const hh = date.getHours();
                // const min = date.getMinutes();
                // const dd = date.getDate();
                // const mm = date.getMonth();
                // const yy = date.getFullYear();
                //
                // const dateUTC = new Date(Date.UTC(yy, mm, dd, hh, min, 0)).toISOString();


                let requested_date = this.selected_availability_slot.timeslot;

                var requestBody = {
                    datetime: requested_date,
                    no_of_guests: this.number_of_guests,
                    smoking_preference: this.smoking_preference,
                    proceed_without_item: false
                } as any;
                if (this.restaurantDetails.has_table_reservation_management) {
                  requestBody.preferred_area = this.preferred_area ? this.preferred_area : 0
                }

                await store.dispatch('sendTableRequest', [requestBody, this.restaurantDetails.id]).then(res => {
                    if(res) {
                        console.log(res);
                        this.send_table_response = res;
                        if(this.send_table_response) {
                            let response = this.send_table_response as any;
                            // Booking Condition
                            if(this.restaurantDetails.has_booking_only && (response.no_show_fee || response.deposit)) {
                                    vm.orderID = response.id;
                                    console.log('payment for table only', response);
                                    vm.showPaymentModel();
                                
                            } else {
                                console.log('table only', response);
                                store.dispatch('makeTableOnly', [{}, response.id]).then(res => {
                                if(res) {
                                  console.log(res);
                                  vm.showRequestSentModal();
                                }
                              }).catch((error) => console.log('Error ', error));

                            }                            
                        }
                    } 
                })
                .catch(error => console.log(error));     
            },

            showPaymentModel(){
                const triggerPayment = this.$refs.triggerPayment as HTMLInputElement;
                triggerPayment.click();
            },

            showRequestSentModal() {
                const triggerRequest = this.$refs.triggerRequest as HTMLInputElement;
                triggerRequest.click();
            },

            formatISO(date: string) {
                if(date) 
                return date.split("-").join("").split(":").join("").slice(0,15).concat("Z");
            },
        }
  });
