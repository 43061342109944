
  import { defineComponent} from 'vue';
  import ForgotPasswordModal from '@/components/pages/auth/ForgotPasswordModal.vue';
  import LoginSideImage from '@/components/pages/auth/LoginSideImage.vue';
  import { defineRule , Form, Field, ErrorMessage } from 'vee-validate';
  import AllRules from '@vee-validate/rules';
  import { store } from '@/store';
  import Facebook from '@/components/pages/auth/signup/Facebook.vue';
  import Google from '@/components/pages/auth/signup/Google.vue';

  Object.keys(AllRules).forEach(rule => {
    defineRule(rule, AllRules[rule]);
  });

  export default defineComponent({
    components: { 
      Form, Field, ErrorMessage, ForgotPasswordModal, LoginSideImage, Facebook, Google
    },
    name: 'Login',
    data() {
      return {
        errors: null,
        email: '',
        password: '',
        logging: false,
        wrong_credentials: false
      }
    },
  
    methods: {

      async SignIn(){
        this.logging = true;
        
        let loginDetails = { username: this.email, password: this.password };

        // dispatch to userAuth action
        await store.dispatch('Login', loginDetails).then(res =>
          { 
            if(res) {
              this.wrong_credentials = false; // hide longin error msg if exist
            }
          }
        )
        .catch(error => {
          if(error) {
            this.logging = false;           // disable the loading in login button 
            this.wrong_credentials = true;  // show longin error msg 
          }
        }); 
    
      }
    }
  });
