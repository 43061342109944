
import { defineComponent } from '@vue/runtime-core';
import { store } from '@/store';
import { initFacebook, login, logout } from '@/sso/facebook/FacebookAuth';
export default defineComponent({
  name: 'SignIn',

  data(){
    return {
        userRequest: {} as any
    }
  },

  created(){
    initFacebook('208811497749779');
  },
  methods: {
    async signIn() {
      const result = await login();
      if (result) {
        if(result.authResponse && result.authResponse.accessToken) {
            this.userRequest.access_token = result.authResponse.accessToken;
        }

        if(result.authResponse && result.authResponse.userID) {
            this.userRequest.code = result.authResponse.userID;
        }

        if(result.authResponse && result.authResponse.userID) {
            this.userRequest.id_token = result.authResponse.userID;
        }

        if(this.userRequest && this.userRequest.access_token) {
            store.dispatch('FacebookLogin', this.userRequest).then( res =>{
                console.log(res);
            });
        }
      }
    },

    async signOut() {
      logout();
    }
  }
});
