
    import { defineComponent } from 'vue';
    import { store } from '@/store';

    export default defineComponent({
        name: 'PaymentModal',
        props: ['restaurantDetails', 'orderID'],

        data() {
            return {
                isNewMethod: false,
                cards: [],
                saved_cards: [],
                selected_card_id: null,
                payment_selected: false,
                contents: null
            }
        },

        created() {
            // get user profile
            if(store.state.user_profile) {
                let user_profile = store.state.user_profile as any;
                this.saved_cards  = user_profile.jcc_saved_cards ? user_profile.jcc_saved_cards : [];
            }
        },

        methods: {
            selectCard(card: any){
                if(!card)
                    return;
                
                this.selected_card_id = card.id;
                this.payment_selected = true;
            },
            addNewPayment(){
                // Add payment method
                this.isNewMethod = true;
            },

            async payNow() {
                const requestBody = {} as any;
                if(this.selected_card_id)
                    requestBody.saved_card = this.selected_card_id;

                await store.dispatch('makeTableOnly', [requestBody, this.orderID]).then(res => {
                    if(res) {
                        console.log(res);
                        if(res.transaction && res.transaction.id) {
                            let redirect_to = window.location.href; 
                            let url = `${process.env.VUE_APP_ROOT_API}/authorize_payment/${res.transaction.id}?auth_token=${store.state.tokenKey}&redirect_to=${redirect_to}`;
                            window.open(url, '_blank');
                        }
                    } 
                })
                .catch((error) => console.log('Error ', error));
            }
        }

    });
