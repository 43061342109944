
    import { defineComponent } from 'vue';
    import Header from '@/components/shared/PageHeader.vue';
    import Footer from '@/components/shared/PageFooter.vue';
    import BookTableTab from '@/components/pages/restaurants/Book.vue';
    import RestaurantMenu from '@/components/pages/restaurants/Menu.vue';
    import DetailsTab from '@/components/pages/restaurants/Details.vue';
    import { store } from '@/store';
    import PageLoader from '@/components/shared/loaders/PageLoader.vue';
    import PaymentSuccess from './modals/PaymentSuccess.vue';
    import slugify from '@/data/GeneralMixins';
    import Slider from '@/components/shared/Slider.vue';
    
    export default defineComponent({
        name: 'Restaurants',
        
        components: { 
            Header, Footer, BookTableTab, RestaurantMenu, DetailsTab, PageLoader, PaymentSuccess, Slider
        }, 

        mixins: [slugify],
       
        data() {
            return {
                currentTab: 'details',
                restaurant_id: null as any,
                restaurant_details: null as any,
                special_menus: null as any,
                dine_in_hours: [] as any
            }
        },

        created() {
            this.restaurant_id = this.$route.params.id;
            if(this.$route.params.tab) {
                let tab = this.$route.params.tab as any;
                this.currentTab = tab;
            } else 
                this.currentTab = 'details';
            
            if(this.restaurant_id) {
                this.restaurantByID(this.restaurant_id);
                this.loadSpecialMenus(this.restaurant_id);
            }
                
           if( this.$route.query.Success && this.$route.query.ReasonCode && this.$route.query.ReasonCode === '1') {
                let vm = this;
                setTimeout(() => {
                    vm.showSuccessPayment();
                }, 1000)
           }
        },

        methods: {
            async restaurantByID(ID: number) {
                await store.dispatch('restaurantByID', ID).then(res => {
                    if(res) {
                        this.restaurant_details = res;
                        if(res.images && res.images.length > 0)
                            this.loadImages(res.images)
                    }
                });
            },
 
            activeClass(item: string){
                if(this.currentTab == item)
                return 'show active';
                else
                return ''
            },

            getAddress(address: any){
                // Format the restauarant address
                let full_address = address.address_line1 ? address.address_line1 : '';
                full_address += address.address_line1 ? ' ' + address.address_line1 : '';
                full_address += address.city && address.city.name ? ' ' + address.city.name : '';
                full_address += address.city && address.city.country && address.city.country.name ? ' ' + address.city.country.name : '';
                return full_address;
            },

            loadImages(images : any){
                let featured_image =  this.restaurant_details.featured_photo_web ? this.restaurant_details.featured_photo_web : 
                                        this.restaurant_details.featured_photo_thumbnails.full;

                if(featured_image) {
                    images.unshift(
                        {
                            id: 0,
                            image: featured_image
                        }
                    )         
                }
            },

            showSuccessPayment() {
                const triggerPayment =  this.$refs.triggerSuccess as HTMLInputElement;
                if(triggerPayment)
                    triggerPayment.click();
            },

            loadSpecialMenus(ID: number){
                store.dispatch('specialMenuByRestaurantID', ID).then((res) =>
                    {
                        if(res) {
                            this.special_menus = res;
                        }
                        
                    }
                )
            },

            hasItems(menu_id: number) {
                if(menu_id) {
                    let index = this.special_menus.filter((x:any) => (x.id === menu_id));
                    if(index != null && index){
                        index[0].hasItems = true;
                    }
                }
            },

            updateURL(tab: string) {
                if(!tab)
                    return;

                let url = `/restaurants/${this.$route.params.slug}/${this.$route.params.id}/${this.slugify(tab)}`
                this.$router.push({path: url });
            }
        }

  });
