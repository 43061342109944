
    import { defineComponent } from 'vue';
    import LoginSideImage from '@/components/pages/auth/LoginSideImage.vue';
    import { defineRule, Form, Field, ErrorMessage } from 'vee-validate';
    import AllRules from '@vee-validate/rules';
    import { store } from '@/store';

    Object.keys(AllRules).forEach(rule => {
        defineRule(rule, AllRules[rule]);
    });

  export default defineComponent({
    name: 'SavePassword',
    components: { 
      Form, Field, ErrorMessage, LoginSideImage
    },
    props: ['email'],
    
    data() {
      return {
        user_email: null as any,
        email_code: null as any,
        saving: false,
        password: null,
        errorMessage: null,
        confirm_password: null,
        valid: false,
        confirm_message: '',
        submited: false,
        terms: false,
        privacy: false
      }
    },

    created() {
      if(!store.state.email_code || !store.state.email) {
        this.user_email = localStorage.getItem('email');
        this.email_code = localStorage.getItem('email_verification');
      } else {
         this.user_email = store.state.email;
          this.email_code = store.state.email_code
      }
    },

    methods: {
      async SavePassword() {
        let vm = this;

        if(this.password && this.confirm_password && this.user_email && this.email_code) {
          let requestBody = { 
            email:  this.user_email, 
            password1: this.password, 
            password2: this.confirm_password,
            verification_code: this.email_code 
          };

          await store.dispatch('Register', requestBody).then(res => {
            if(res && res == 'ok') {
              vm.confirm_message = 'Password changed successfully';
            } else {
              vm.confirm_message = 'Could not change you password, please try again.';
            }
          });
        } else {
          console.log('Something went wrong!')
        }
      },

      onFormUpdated(){
        // Check if password is validated and Terms & Privacy are accepted.
        if(this.password && this.confirm_password == this.password && this.terms && this.privacy)
          this.valid = true;
         else 
          this.valid = false;
      },
    }

  });
