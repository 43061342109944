
import { defineComponent } from "vue";

export default defineComponent ({
    data() {
        return { 
            currency_symbols: {
                USD: '$', // US Dollar
                EUR: '€', // Euro
                CRC: '₡', // Costa Rican Colón
                GBP: '£', // British Pound Sterling
                ILS: '₪', // Israeli New Sheqel
                INR: '₹', // Indian Rupee
                JPY: '¥', // Japanese Yen
                KRW: '₩', // South Korean Won
                PLN: 'zł', // Polish Zloty
                PYG: '₲', // Paraguayan Guarani
                THB: '฿', // Thai Baht
                UAH: '₴', // Ukrainian Hryvnia
                VND: '₫', // Vietnamese Dong
            } as any
        }
    },
    methods: {
        truncate (data: string, num: number){
            if(!data)
                return;
            if(data.length > num) {
                const reqString = data.split("").slice(0, num).join("");
                return reqString + '...';
            } else {
                return data
            }
        },
        slugify(text: string) {
            if(text)
                return text
                    .toLowerCase()
                    .trim()
                    //.replace(/[^\w\s-]/g, '')
                    .replace(/&/g, '')
                    .replace(/'"/g, '')
                    .replace(/[\s_-]+/g, '-')
                    .replace(/^-+|-+$/g, '');

        },
        getCurrency(currency: string) {
            const currency_symbols = this.currency_symbols[currency] ? this.currency_symbols[currency] : currency;
            return currency_symbols;
        },

        restaurantURL(id: number, name: string) {
            name = name ? name : 'details';
            return '/restaurants/' + this.slugify(name) + '/' + id;
        }
    }
});