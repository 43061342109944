
    import { defineComponent } from 'vue';
    
    export default defineComponent({
        name: 'RequestSentModal',
        props: ['social_links'],

        methods: {
            redirectToPage() {
               location.reload();
            }
        }
    });
