import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-column min-vh-100" }
const _hoisted_2 = { class: "primary-gradient-bg text-center my-5 py-5" }
const _hoisted_3 = { class: "container container-fluid" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col-md-8 offset-md-2" }
const _hoisted_6 = { class: "text-white" }
const _hoisted_7 = { class: "container container-fluid" }
const _hoisted_8 = { class: "row d-flex" }
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = { class: "col-md-4 mb-4" }
const _hoisted_11 = ["src"]
const _hoisted_12 = {
  key: 1,
  src: "/images/team-photo.png",
  alt: "Our Team",
  class: "w-100"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_PageLoader = _resolveComponent("PageLoader")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Header),
    (_ctx.page_items != null)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("section", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("h5", _hoisted_6, _toDisplayString(_ctx.page_items.header_text), 1)
                ])
              ])
            ])
          ]),
          _createElementVNode("section", null, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", {
                  class: "col-md-8 mb-4",
                  innerHTML: _ctx.page_items.team_text
                }, null, 8, _hoisted_9),
                _createElementVNode("div", _hoisted_10, [
                  (_ctx.page_items && _ctx.page_items.team_photo)
                    ? (_openBlock(), _createElementBlock("img", {
                        key: 0,
                        src: _ctx.page_items.team_photo,
                        alt: "Our Team",
                        class: "w-100"
                      }, null, 8, _hoisted_11))
                    : (_openBlock(), _createElementBlock("img", _hoisted_12))
                ])
              ])
            ])
          ])
        ], 64))
      : (_openBlock(), _createBlock(_component_PageLoader, { key: 1 })),
    _createVNode(_component_Footer)
  ]))
}